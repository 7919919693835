
$(document).ready(function() {
    jumpMenu();
    common();
    bannerSlider();
    goToTop();
    blogSharing();
    lazyMap();
    loadGoogleFont();
    appWidgetFeed($('.elfsight-app'));
});


//b版型的id不太一樣
function goToTop() {
    var $scrollToTop = $('#scroll_totop');
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $scrollToTop.fadeIn();
        } else {
            $scrollToTop.fadeOut();
        }
    });

    //Click event to scroll to top
    $scrollToTop.click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
}

function appWidgetFeed($initLoadElemWrapper) {
    if($initLoadElemWrapper.length > 0){
        var isEnable = false;
        $initLoadElemWrapper.on('inview', function (event, isInView) {
            if(isInView && isEnable == false){
                isEnable = true;
                var appWidgets = document.createElement("script");
                appWidgets.async = true;
                if(window.MYAPP.hasOwnProperty('apps_widget_endpoint')){
                    appWidgets.src = window.MYAPP.apps_widget_endpoint; 
                    $("head").append(appWidgets);
                }
            }  
        });
    }
}

function jumpMenu() {

    var jumpOffset = -$('.header').height();
    console.log('jumpMenu => offset:' + jumpOffset);

    //選單跳段落
    $('.header a').click(function() {

        console.log('click menu link:' + this.hash);

        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
            jumpSection(this.hash,jumpOffset,500);
        }
        // return false;
    });

    /*才能避免slider產生後的高度讓跳cut不正確*/
    var jumpHash = location.hash;
    //超連結跳段落
    if (jumpHash !== '') {
        var newjumpHash = jumpHash.replace(/\//, '');
        console.log('detect jump from link url:');
        Pace.on('done', function() {
            jumpSection(newjumpHash,jumpOffset,500);
            // return false;
        });
    }

}

/*因為延遲載入不能用動畫拖拉
，會因圖片載入後高度不一致導致捲軸位置不精確*/
// function jumpSection(hashId, jumpAnimateSecs) {
//     console.log('jumpSection:' + hashId);
//     var target = $(hashId);

//     console.log(hashId + ' target elem =>  top pos:' + target.offset().top);

//     // target = target.length ? target : $('[name=' + hashId.slice(1) + ']');
//     if (target.length > 0) {

//         console.log('scroller !! find link target:' + hashId);

//         $('html,body').animate({
//             scrollTop: target.offset().top
//         }, 0);

//         return false;
//     } else {
//         console.warn('cannot find jumpSection #id:' + hashId);

//     }
// }

function bannerSlider() {
    var bSlider = null;
    // Pace.on('done', function() {
        var $bannerSlider = $('#bannerSlider');
        if ($bannerSlider.length > 0) {

            setMasterSliderImageOnScreen($bannerSlider);

            try {
                bSlider = new MasterSlider();
                // adds Arrows navigation control to the slider.

                bSlider.control('timebar', {
                    insertTo: '#bannerSlider'
                });
                bSlider.control('bullets');

                bSlider.control('circletimer', { autohide: false, overVideo: true, color: '#FFFFFF', radius: 4, stroke: 9 });

                bSlider.control('arrows', { autohide: true });
                bSlider.setup('bannerSlider', {
                    width: 1440, // slider standard width
                    height: 563, // slider standard height
                    minHeight: 400,
                    autoHeight: true,
                    start: 1,
                    space: 0,
                    layout: 'fullwidth',
                    loop: true,
                    preload: 0,
                    instantStartLayers: false,
                    autoplay: true,
                    overPause: true
                });
                // $(window).trigger('resize');
                $('.master-skeleton-loader').remove();
                
            } catch (err) {
                console.error(err);
                removeErrorMasterSliderContainer($bannerSlider);
            }
        }
    // });
}


function blogSharing() {
    initJsSocial($('#blogSharing'));
}

function initJsSocial($targetElem) {
    if ($targetElem.length > 0) {
        $targetElem.jsSocials({
            shares: ["twitter", "facebook"]
        });
    }
}

function lazyMap() {
    Pace.on('done', function() {
        initViewLazyOfIframe($('#homeMap'));
    });
}

//第三方iniview載入機制
function initViewLazyOfIframe($targetElem) {
    var isEnable = false;
    if ($targetElem.length > 0) {
        $targetElem.on('inview', function(event, isInView) {
            if (isInView) {
                if (!isEnable) {
                    $targetElem.attr('src', $targetElem.attr('data-url'));
                    isEnable = true;
                }
            }
        });
    }
}

function loadGoogleFont() {
    Pace.on('done', function () {
        WebFont.load({
            timeout: 2000,
            google: {
                families: [
                    'Noto Sans TC:300,400,600,700,800&display=swap',
                    'Roboto&display=swap'
                ]
            }
        });
    });
}
